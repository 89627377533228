import React from 'react';
import Article from './types/article/Article';
import Event from './types/Event';

import './Post.scss';

const Post = props => {
    const { data = {} } = props;
    const { articleType } = data;

    return (articleType === 'article' && <Article {...props} />) || (articleType === 'event' && <Event {...props} />);
};

export default Post;
