export const stripHtml = html => {
    const cleanHtml = html?.trim() || '';
    const doc = new DOMParser().parseFromString(cleanHtml, 'text/html');
    return doc.body.textContent || '';
};

export const createDefaultEventDetails = ({ eventDetails, data, mobileBannerImage }) => ({
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: eventDetails[0]?.eventTitle ? eventDetails[0].eventTitle : '',
    description: stripHtml(data.shortDescription) || '',
    image: mobileBannerImage || '',
    eventStatus: 'https://schema.org/EventScheduled',
    location: {},
});

export const createPerformer = speakers => {
    return speakers.map(speaker => ({
        '@type': 'Person',
        name: speaker.name,
        image: speaker.image,
    }));
};

const generateSchemaMarkup = props => {
    const { data } = props;
    const { eventDetails = [], mobileBannerImage, publishDateTime } = data;

    const toISOString = timestamp => {
        return new Date(timestamp).toISOString();
    };

    const eventData = createDefaultEventDetails({ eventDetails, data, mobileBannerImage })

    if (eventDetails[0] && eventDetails[0].speakers && eventDetails[0].speakers.length) {
        eventData.performer = createPerformer(eventDetails[0].speakers);
    }

    switch (eventDetails[0]?.eventType) {
        case 'Live Webinar':
        case 'On-Demand':
            eventData.startDate = toISOString(eventDetails[0].eventType === 'Live Webinar' ? eventDetails[0].eventDateTime : publishDateTime);
            eventData.eventAttendanceMode = 'https://schema.org/OnlineEventAttendanceMode';
            eventData.location['@type'] = 'VirtualLocation';
            eventData.location.url = window.location.href;
            break;
        case 'Physical Event':
            eventData.startDate = toISOString(eventDetails[0].eventDateTime);
            eventData.eventAttendanceMode = 'https://schema.org/OfflineEventAttendanceMode';
            eventData.location['@type'] = 'Place';
            eventData.location.address = {
                '@type': 'PostalAddress',
                streetAddress: eventDetails[0].address,
                addressLocality: eventDetails[0].location,
            };
            break;
    }

    const schemaScript = document.createElement('script');
    schemaScript.type = 'application/ld+json';
    schemaScript.innerHTML = JSON.stringify(eventData);
    document.head.appendChild(schemaScript);
};

export default generateSchemaMarkup;