const handleFieldInteraction = (event, errorType, errorElement) => {
    const fieldUpdate = getFieldUpdate(event, errorType);

    switch (errorElement) {
        case 'error1':
        case 'error2':
            handleTextError(fieldUpdate, errorType);
            break;
        case 'error3':
            handleTelError(fieldUpdate, errorType);
            break;
        case 'error4':
            handleEmailError(fieldUpdate, errorType);
            break;
        case 'error5':
            handleSelectError(fieldUpdate, errorType);
            break;
        case 'error6':
        case 'error7':
            handleTextError(fieldUpdate, errorType);
            break;
        case 'error8':
            handleSelectError(fieldUpdate, errorType);
            break;
        case 'error9':
            handleSelectError(fieldUpdate, errorType);
            break;
        case 'error10':
            handleTextError(fieldUpdate, errorType);
            break;
    }
};

const getFieldUpdate = (event, errorType) => {
    return errorType === 'fieldInteraction' ? event.target : errorType === 'submitInteraction' ? event : null;
};

const showError = (field, element) => {
    if (!field.classList.contains('error')) {
        field.classList.add('error');
        const fieldLabelText = document.querySelector(`[for='${field.id}'] span`).textContent.toLowerCase();
        let errorMessage;

        switch (element) {
            case 'text':
                errorMessage = `Please enter your ${fieldLabelText}`;
                break;
            case 'tel':
                errorMessage = 'Please enter a valid phone number';
                break;
            case 'email':
                errorMessage = 'Please enter a valid email address';
                break;
            case 'select':
                errorMessage = `Please select your ${fieldLabelText}`;
                break;
        }

        field.insertAdjacentHTML(
            'afterend',
            `<span class="form-error is-visible">
                <span class="sr-only">${errorMessage}</span>
            </span>
            <div class="form-group error-block">
                <div class="alert alert-danger" role="alert">
                    <span>${errorMessage}</span>
                </div>
            </div>`
        );
    }
};

const hideError = (error, field) => {
    if (error === 'fieldInteraction' && field.classList.contains('error')) {
        field.classList.remove('error');
        field.nextElementSibling.remove();
        field.nextElementSibling.remove();
    }
};

const handleTextError = (fieldUpdate, errorType) => {
    if (!isValidText(fieldUpdate.value)) {
        showError(fieldUpdate, 'text');
    } else {
        hideError(errorType, fieldUpdate);
    }
};

const handleTelError = (fieldUpdate, errorType) => {
    if (!isValidTel(fieldUpdate.value)) {
        showError(fieldUpdate, 'tel');
    } else {
        hideError(errorType, fieldUpdate);
    }
};

const handleEmailError = (fieldUpdate, errorType) => {
    if (!isValidEmail(fieldUpdate.value)) {
        showError(fieldUpdate, 'email');
    } else {
        hideError(errorType, fieldUpdate);
    }
};

const handleSelectError = (fieldUpdate, errorType) => {
    if (fieldUpdate.value === 'none') {
        showError(fieldUpdate, 'select');
    } else {
        hideError(errorType, fieldUpdate);
    }
};

const isValidText = value => value.match(/\S(.*\S)?/) && value.match(/^([^0-9]*)$/);

const isValidTel = value => value.match(/\S(.*\S)?/) && value.match(/^\(?\+?[\d\(\-\s\)]+$/);

const isValidEmail = value => value.match(/\S(.*\S)?/) && value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

export default handleFieldInteraction;
