import React from 'react'

const renderAddressDetails = (address) => {
    return (
        <section id="address-details">
            <div className="h4 header-bold">Address</div>
            <div
                dangerouslySetInnerHTML={{
                    __html: address.replace(/,/g, ',<br />'),
                }}
            ></div>
        </section>
    );
};

export default renderAddressDetails