import React from 'react';
import Equalizer from 'react-equalizer';
import classNames from 'classnames';

import request from '../../../../http/request';
import Dropdown from '../../../shared/Dropdown/Dropdown';
import ReactDOMServer from 'react-dom/server';
import SweetAlert from 'sweetalert-react';
import { currentLocale, getUrl, miniGazelle, isClient } from '../../../../helpers';
import { renderAddressDetails, renderAccessibility, renderSubmit } from '../helpers/components';
import { generateSchemaMarkup, loadFormsPlusScript, handleFieldInteraction, outputDate } from '../helpers';

import OnDemandSvg from '../../../../assets/images/icons/events/on-demand.svg';
import LiveWebinarSvg from '../../../../assets/images/icons/events/live-webinar.svg';
import LocationPhysicalEventSvg from '../../../../assets/images/icons/events/location-physical-event.svg';

class Event extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileWidth: true,
            countriesList: [],
            needsList: [],
            allergiesList: [],
            selectedNeeds: [],
            selectedAllergies: [],
            submitSendingForm: false,
            titlesList: [],
            companyTypesList: [],
            // submitCaseNumber: '',
            submitSuccess: false,
            submitError: false,
            submitNoAvailability: false,
            // submitDuplicate: false
        };
        this.setupResponsiveStyling.bind(this);
        this.loadMk2FormsToScript.bind(this);
        this.addSweetAlertClass.bind(this);
    }

    componentDidMount() {
        if (isClient()) {
            this.setupResponsiveStyling();
        }

        // generate ld+json event schema (singular)
        generateSchemaMarkup(this.props);
        // load MktoForms2 script
        this.loadMk2FormsToScript(this.props);
        // load FormsPlus script
        loadFormsPlusScript();
        // deal with URL redirect BUG
        const params = new URL(document.location).searchParams;
        // quick bug fix to deal with auto-redirect if 'Live Webinar', 'Physical Event' or 'Private Event'
        // ...show the success dialog if Marketo's aliId is parsed
        // ...only thing is I can't quickly parse the ID of the booking; will be in email sent out though
        if (params.has('aliId')) {
            this.setState({
                submitSuccess: true,
                // submitCaseNumber: params.get('referenceId')
            });
        }
        // deal with adding class for sweet-alert
        this.addSweetAlertClass();
    }

    loadMk2FormsToScript() {
        const script1 = document.createElement('script');
        script1.src = '/assets/scripts/forms2.min.js';
        script1.async = true;
        document.body.appendChild(script1);
        script1.onload = () => {
            // loadForm
            const basePath = getUrl();
            let formId;
            const mediaSolutionsEvents =
                this.props.dataLayer.categories.includes('cinematography') ||
                this.props.dataLayer.categories.includes('filmmaking') ||
                this.props.dataLayer.categories.includes('newsproduction') ||
                this.props.dataLayer.categories.includes('liveproduction') ||
                this.props.dataLayer.categories.includes('creatorscloud');
            const healthcareEvents = this.props.dataLayer.categories.includes('healthcare');
            const digitalImagingEvents = this.props.dataLayer.categories.includes('digitalimaging');
            const retailEvents = this.props.dataLayer.categories.includes('retail');
            const corporateEvents = this.props.dataLayer.categories.includes('corporate');
            const educationEvents = this.props.dataLayer.categories.includes('education');
            const virtualProductionEvents = this.props.dataLayer.categories.includes('virtualProduction');

            const formIds = {
                mediaSolutionsEvents: 552,
                healthcareEvents: 718,
            };

            if (
                basePath === 'http://localhost:3000' ||
                basePath === 'http://localhost:3001' ||
                basePath === 'https://stg-preview.pro.sony' ||
                basePath === 'https://preview.pro.sony'
            ) {
                // Live Webinar event
                if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && mediaSolutionsEvents) {
                    formId = 552;
                } else if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && healthcareEvents) {
                    formId = 718;
                } else if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && digitalImagingEvents) {
                    formId = 737;
                } else if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && retailEvents) {
                    formId = 739;
                } else if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && educationEvents) {
                    formId = 744;
                } else if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && corporateEvents) {
                    formId = 741;
                }

                // On-demand event
                else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && mediaSolutionsEvents) {
                    formId = 553;
                } else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && healthcareEvents) {
                    formId = 717;
                } else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && digitalImagingEvents) {
                    formId = 738;
                } else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && retailEvents) {
                    formId = 740;
                } else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && corporateEvents) {
                    formId = 742;
                } else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && educationEvents) {
                    formId = 745;
                }

                // Phsyical event
                else if (this.props.data.eventDetails[0].eventType === 'Physical Event' && mediaSolutionsEvents) {
                    formId = 550;
                } else if (this.props.data.eventDetails[0].eventType === 'Physical Event' && healthcareEvents) {
                    formId = 550;
                }

                // Private event
                else if (this.props.data.eventDetails[0].eventType === 'Private Event' && mediaSolutionsEvents) {
                    formId = 551;
                } else if (this.props.data.eventDetails[0].eventType === 'Private Event' && healthcareEvents) {
                    formId = 551;
                }
                window.MktoForms2.loadForm('//app-ab37.marketo.com', '293-AAU-450', formId);
            } else if (basePath === 'https://pro.sony') {
                // live instance

                // Live webinar event
                if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && mediaSolutionsEvents) {
                    formId = 3184;
                } else if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && healthcareEvents) {
                    formId = 4547;
                } else if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && digitalImagingEvents) {
                    formId = 6572;
                } else if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && retailEvents) {
                    formId = 6574;
                } else if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && corporateEvents) {
                    formId = 7173;
                } else if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && educationEvents) {
                    formId = 7175;
                } else if (this.props.data.eventDetails[0].eventType === 'Live Webinar' && virtualProductionEvents) {
                    formId = 7307;
                }

                // On-demand event
                else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && mediaSolutionsEvents) {
                    formId = 3185;
                } else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && healthcareEvents) {
                    formId = 4548;
                } else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && digitalImagingEvents) {
                    formId = 6573;
                } else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && retailEvents) {
                    formId = 6575;
                } else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && corporateEvents) {
                    formId = 7178;
                } else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && educationEvents) {
                    formId = 7179;
                } else if (this.props.data.eventDetails[0].eventType === 'On-Demand' && virtualProductionEvents) {
                    formId = 7308;
                }

                // Physical event
                else if (this.props.data.eventDetails[0].eventType === 'Physical Event' && mediaSolutionsEvents) {
                    formId = 7314;
                } else if (this.props.data.eventDetails[0].eventType === 'Physical Event' && healthcareEvents) {
                    formId = 7310;
                } else if (this.props.data.eventDetails[0].eventType === 'Physical Event' && corporateEvents) {
                    formId = 7188;
                } else if (this.props.data.eventDetails[0].eventType === 'Physical Event' && educationEvents) {
                    formId = 7189;
                } else if (this.props.data.eventDetails[0].eventType === 'Physical Event' && virtualProductionEvents) {
                    formId = 7309;
                }

                // Private event
                else if (this.props.data.eventDetails[0].eventType === 'Private Event') {
                    formId = 3182;
                }
                window.MktoForms2.loadForm('//eu.info.pro.sony', '063-EBK-070', formId);
            }

            window.MktoForms2.whenReady(form => {
                const createSelectArray = type => {
                    const outputArray = [];
                    let marketoElement;
                    switch (type) {
                        case 'country':
                            marketoElement = form.getFormElem().find('#Country')[0];
                            Array.from(marketoElement).forEach(option => {
                                outputArray.push({
                                    value: option.value,
                                    text: option.text,
                                });
                            });
                            // remove first 'Select...' text
                            outputArray.shift();
                            this.setState({
                                countriesList: outputArray,
                            });
                            break;
                        case 'jobTitleList':
                            marketoElement = form.getFormElem().find('#Title')[0];
                            Array.from(marketoElement).forEach(option => {
                                outputArray.push({
                                    value: option.value,
                                    text: option.text,
                                });
                            });
                            // remove first 'Select...' text
                            outputArray.shift();
                            this.setState({
                                titlesList: outputArray,
                            });
                            break;
                        case 'companyTypeList':
                            marketoElement = form.getFormElem().find('#Company_Type__c')[0];
                            Array.from(marketoElement).forEach(option => {
                                outputArray.push({
                                    value: option.value,
                                    text: option.text,
                                });
                            });
                            // remove first 'Select...' text
                            outputArray.shift();
                            this.setState({
                                companyTypesList: outputArray,
                            });
                            break;
                        case 'needs':
                            marketoElement = form.getFormElem().find('#AccessibilityNeeds')[0];
                            Array.from(marketoElement).forEach(option => {
                                outputArray.push({
                                    value: option.value,
                                    label: option.text,
                                });
                            });
                            // remove first 'Select...' text
                            outputArray.shift();
                            this.setState({
                                needsList: outputArray,
                            });
                            break;
                        case 'allergies':
                            marketoElement = form.getFormElem().find('#Allergies')[0];
                            Array.from(marketoElement).forEach(option => {
                                outputArray.push({
                                    value: option.value,
                                    label: option.text,
                                });
                            });
                            // remove first 'Select...' text
                            outputArray.shift();
                            this.setState({
                                allergiesList: outputArray,
                            });
                            break;
                    }
                };

                // create country list dynamically from Marketo
                createSelectArray('country');
                // create job title list dynamically from Marketo
                createSelectArray('jobTitleList');
                // create company type list dynamically from Marketo
                createSelectArray('companyTypeList');

                // if 'Physical Event' or 'Private Event'
                if (
                    this.props.data.eventDetails[0].eventType === 'Physical Event' ||
                    this.props.data.eventDetails[0].eventType === 'Private Event'
                ) {
                    // create needs list dynamically from Marketo
                    createSelectArray('needs');
                    // create allergies list dynamically from Marketo
                    createSelectArray('allergies');
                }
            });
        };
    }

    addSweetAlertClass() {
        document.querySelector('body').classList.add('event-sweet-alert-class-needed');
    }

    setupResponsiveStyling() {
        const updateSize = () => {
            const mobileWidth = document.body.clientWidth < 768;
            this.setState({ mobileWidth });
        };

        updateSize();
        window.addEventListener('resize', updateSize);
    }

    // react-select logic
    handleMultiSelect(event, element) {
        if (element === 'accessibilityNeeds') {
            this.setState({ selectedNeeds: event });
        } else if (element === 'allergies') {
            this.setState({ selectedAllergies: event });
        }
    }

    // submit form logic
    handleSubmit(event, eventType, videoLink, capacity) {
        event.preventDefault();

        const errorsObject = {
            // error-check 'register-now' form
            error1: document.forms['register-now']['firstName'],
            error2: document.forms['register-now']['lastName'],
            error3: document.forms['register-now']['phoneNumber'],
            error4: document.forms['register-now']['emailAddress'],
            error5: document.forms['register-now']['country'],
            error8: document.forms['register-now']['companyType'],
            error9: document.forms['register-now']['title'],
            error10: document.forms['register-now']['company'],
        };

        // scan through each potential error
        const checkFields = key => {
            handleFieldInteraction(
                // errorsObject.error1 html target etc
                errorsObject[key],
                // differentiate from 'fieldInteraction'
                'submitInteraction',
                // 'error1' string etc
                key
            );
        };
        Object.keys(errorsObject).forEach(key => {
            // only if no error already present
            if (!errorsObject[key].classList.contains('error')) {
                checkFields(key);
            }
        });

        // flag potential form error
        let formErrorStatus = false;
        // self-contained iterative loop logic
        (() => {
            for (const item in errorsObject) {
                // if error registered...
                if (errorsObject[item].classList.contains('error')) {
                    // alert('NOT READY TO SUBMIT');
                    const errorElement = errorsObject[item].closest('div.form-group');
                    // if error then auto-scroll user to field that needs attention
                    window.scrollTo({
                        top:
                            errorElement.getBoundingClientRect().top +
                            window.scrollY -
                            // need to subtract sticky height
                            document.querySelector('.sticky-outer-wrapper').clientHeight,
                        behavior: 'smooth',
                    });
                    // switch flag
                    formErrorStatus = true;
                    // break loop logic on first error registered
                    return true;
                }
            }
        })();

        // if no errors registered - POST data logic...
        if (formErrorStatus === false) {
            // alert('READY TO SUBMIT');
            this.setState({
                submitSendingForm: true,
            });

            // generate uniqueKey
            let eventId = this.props.metadata.logicalName;
            const timestamp = new Date().getTime();
            const category = this.props.metadata.category.toString();
            const eventDateTime = this.props.data.eventDetails[0].eventDateTime;
            const duration = this.props.data.eventDetails[0].duration;
            const webinarPageLink = this.props.data.eventDetails[0].webinarPageLink;
            const basePath = getUrl();
            const locale = currentLocale.get();

            /**
             * Generate gate video link
             */
            let gatedVideoLink = '';

            switch (eventType) {
                case 'On-Demand':
                    gatedVideoLink = `${basePath}/${locale}/events/on-demand${videoLink}?eventId=${eventId}&referenceId=${timestamp}&audience=${category}`;
                    break;

                case 'Live Webinar':
                    gatedVideoLink = webinarPageLink;
                    break;
                default:
                    break;
            }

            // if succesful Pro.Sony post - execute marketo logic
            const executeMarketoPost = () => {
                // collect user data input
                const fieldMap = [
                    {
                        marketo: 'FirstName',
                        custom: '#field-firstName',
                        type: 'text',
                    },
                    {
                        marketo: 'LastName',
                        custom: '#field-lastName',
                        type: 'text',
                    },
                    {
                        marketo: 'Email',
                        custom: '#field-emailAddress',
                        type: 'email',
                    },
                    {
                        marketo: 'City',
                        custom: '#field-city',
                        type: 'text',
                    },
                    {
                        marketo: 'Country',
                        custom: '#field-country',
                        type: 'select',
                    },
                    {
                        marketo: 'Company_Type__c',
                        custom: '#field-companyType',
                        type: 'select',
                    },
                    {
                        marketo: 'Company',
                        custom: '#field-company',
                        type: 'text',
                    },
                    {
                        marketo: 'Title',
                        custom: '#field-jobRole',
                        type: 'select',
                    },
                    {
                        marketo: 'Phone',
                        custom: '#field-phoneNumber',
                        type: 'tel',
                    },
                    {
                        marketo: 'WebPromoOptIn__c',
                        custom: '#field-promotions',
                        type: 'checkbox',
                    },
                ];
                // if 'Physical Event' or 'Private Event'
                if (eventType === 'Physical Event' || eventType === 'Private Event') {
                    fieldMap.push(
                        {
                            marketo: 'AccessibilityNeeds',
                            custom: this.state.selectedNeeds,
                            type: 'react-select',
                        },
                        {
                            marketo: 'Allergies',
                            custom: this.state.selectedAllergies,
                            type: 'react-select',
                        }
                    );
                }

                // hidden fields required for triggering emails
                fieldMap.push(
                    {
                        marketo: 'Event_Name__c',
                        custom: eventId,
                        type: 'hidden',
                    },
                    {
                        marketo: 'SubmissionReferenceId',
                        custom: timestamp,
                        type: 'hidden',
                    }
                );
                // if 'On-Demand'
                if (eventType === 'On-Demand') {
                    fieldMap.push({
                        marketo: 'videolink',
                        custom: gatedVideoLink,
                        type: 'hidden',
                    });
                }

                const mktoFields = {};
                const customForm = document.querySelector('#register-now');
                // iterate over fields on custom form to create marketoForm compatible object
                fieldMap.forEach(field => {
                    if (field.type === 'text' || field.type === 'email') {
                        // use trim() on input values in case of whitespace
                        mktoFields[field.marketo] = customForm.querySelector(field.custom).value.trim();
                    } else if (field.type === 'select') {
                        mktoFields[field.marketo] = customForm.querySelector(field.custom).value;
                    } else if (field.type === 'tel') {
                        // use regex on input values in case of whitespace
                        mktoFields[field.marketo] = customForm.querySelector(field.custom).value.replace(/\s/g, '');
                    } else if (field.type === 'checkbox') {
                        // capture 'checked' status instead of 'value'
                        mktoFields[field.marketo] = customForm.querySelector(field.custom).checked;
                    } else if (field.type === 'react-select') {
                        // extract out each value into array
                        mktoFields[field.marketo] = field.custom.map(a => a.value);
                    } else if (field.type === 'hidden') {
                        mktoFields[field.marketo] = field.custom;
                    }
                });

                const marketoForm = window.MktoForms2.allForms()[0];
                // add to Marketo form
                marketoForm.addHiddenFields(mktoFields);

                // on Marketo success
                marketoForm.onSuccess(() => {
                    eventType === 'On-Demand'
                        ? // if 'On-Demand'
                          // as after succesful Marketo post, deal with URL redirect BUG - need to refresh; redirect to gatedVideoLink
                          (window.location.href = gatedVideoLink)
                        : // if 'Live Webinar', 'Physical Event' or 'Private Event'
                          // as after succesful Marketo post, deal with URL redirect BUG - need to refresh; parse timestamp ID
                          // (window.location.href = `?success&referenceId=${timestamp}`);
                          // ...redirect above does not work - always parses aliId from Marketo
                          null;

                    // BUG: stop Marketo native URL redirect on submission won't work...
                    // ...if same URL, so no query-string redirects allowed
                    // ...only other obvious solution is to update form in Marketo to point to variables parsed??
                    return false;
                });
                // on Marketo error
                window.FormsPlus.onSubmitError(error => {
                    console.warn(error);
                    // deal with URL redirect BUG - need to refresh
                    // window.location.href = '?error';
                    // ...redirect above does not work - always parses aliId from Marketo
                });

                // submit Marketo form
                marketoForm.submit();
            };

            // Pro.Sony post - this will unlock gatedVideoLink / subtract availability
            const fetchSubmission = () => {
                // sending specific JSON data to Pro.Sony
                const formData = JSON.stringify({
                    eventId: eventId,
                    eventType: eventType,
                    eventCapacity: capacity,
                    referenceId: timestamp,
                    videoLink: gatedVideoLink,
                    eventDateTime,
                    duration,
                });

                const basePath = getUrl();
                const locale = currentLocale.get();
                // POST to formSubmit endpoint will fail on localhost anyway
                let fetchUrl = `${basePath}/${locale}/api/solutions/events/update`;
                let fetch = request
                    .post(fetchUrl)
                    .send(formData)
                    .then(res => {
                        // parse the body
                        const body = res.body;
                        // show success message
                        if (body.status === 'OK') {
                            // collected executeMarketoPost logic
                            executeMarketoPost();

                            // no availability
                        } else if (body.status === 'BANDWIDTH_LIMIT_EXCEEDED') {
                            this.setState({
                                submitNoAvailability: true,
                                submitSendingForm: false,
                            });

                            // user data error
                        } else if (body.status === 'BAD_REQUEST' || body.status === 'INTERNAL_SERVER_ERROR') {
                            this.setState({
                                submitError: true,
                                submitSendingForm: false,
                            });
                        }
                    })
                    .catch((err, res) => {
                        if (err || !res.ok) {
                            this.setState({
                                submitError: true,
                                submitSendingForm: false,
                            });
                        }
                    });
                return fetch;
            };
            // delete after below logic work completed
            fetchSubmission();
        }
    }

    render() {
        const { data = {} } = this.props;
        const { logicalName, bannerImage, mobileBannerImage, eventDetails } = data;

        const {
            eventTitle,
            eventType,
            videoLink,
            eventDescription,
            eventLanguage,
            eventAccessibility,
            eventDateTime,
            duration,
            capacity,
            availability,
            speakers,
            deadlineDateTime,
            contactEmail,
            location,
            address,
        } = eventDetails[0];

        const {
            wheelchairAccess,
            visualImpairment,
            blind,
            hardOfHearing,
            assistiveListening,
            subtitlesAvailable,
            audioDescription,
        } = eventAccessibility[0];

        const { mobileWidth } = this.state;

        const outputNow = () => {
            return new Date().getTime();
        };

        return (
            <div className="post-event" id={logicalName}>
                <div className="container-fluid module">
                    <div className="container">
                        <div className="col-sm-12">
                            {mobileWidth && <h1 className="header-light">{eventTitle}</h1>}
                            {mobileWidth && (
                                <div className="event-context">
                                    <img src={LiveWebinarSvg} alt="live webinar icon" role="presentation" />
                                    <figure>
                                        <img
                                            src={
                                                location
                                                    ? LocationPhysicalEventSvg
                                                    : eventDateTime
                                                    ? LiveWebinarSvg
                                                    : OnDemandSvg
                                            }
                                            alt="event information"
                                            role="presentation"
                                        />
                                    </figure>
                                    <span className="h4 header-bold">{location ? location : eventType}</span>
                                </div>
                            )}
                            <figure id="main-image">
                                <img
                                    src={mobileWidth ? mobileBannerImage : bannerImage}
                                    alt="banner image"
                                    role="presentation"
                                />
                                {eventType !== 'On-Demand' &&
                                    // return true if availability equals zero
                                    (availability === 0 ||
                                        // return true if now more than or equal to deadlineDateTime
                                        outputNow() >= deadlineDateTime) && (
                                        <figcaption className="header-bold">Booking closed</figcaption>
                                    )}
                                {eventType !== 'On-Demand' &&
                                    // return true if availability bigger than zero
                                    availability > 0 &&
                                    // return true if now less than deadlineDateTime
                                    outputNow() < deadlineDateTime &&
                                    // return true if 25% tickets or less remain
                                    capacity / availability >= 4 && (
                                        <figcaption className="header-bold">Limited spaces</figcaption>
                                    )}
                            </figure>

                            {mobileWidth && (
                                <section id="info-panel">
                                    {eventDateTime && (
                                        <div className="h4 header-bold event-date">
                                            {outputDate(eventDateTime, 'time')}
                                        </div>
                                    )}
                                    {duration && (
                                        <div className="event-duration">
                                            <span>Duration:</span> {duration}
                                        </div>
                                    )}
                                    {eventDateTime && (
                                        <div className="h4 header-bold event-date">
                                            {outputDate(eventDateTime, 'day')} {outputDate(eventDateTime, 'month')}
                                        </div>
                                    )}
                                    {deadlineDateTime && (
                                        <div className="event-deadline">
                                            <span>Deadline:</span> {outputDate(deadlineDateTime, 'day')}{' '}
                                            {outputDate(deadlineDateTime, 'month')}
                                        </div>
                                    )}
                                    {deadlineDateTime && (
                                        <div className="event-deadline">{outputDate(deadlineDateTime, 'time')}</div>
                                    )}
                                    <div className="event-detail">
                                        <div>
                                            <figure>
                                                <img
                                                    src="/assets/images/icons/events/language.svg"
                                                    alt="event language"
                                                    role="presentation"
                                                />
                                            </figure>
                                            <span>{eventLanguage}</span>
                                        </div>
                                        {subtitlesAvailable && (
                                            <div>
                                                <figure>
                                                    <img
                                                        src="/assets/images/icons/events/closed-caption.svg"
                                                        alt="event has closed captions"
                                                        role="presentation"
                                                    />
                                                </figure>
                                                <span>Subtitles Available</span>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            )}

                            {!mobileWidth && (
                                <div className="row">
                                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-2"></div>
                                    <section id="info-panel" className="col-xs-12 col-sm-9 col-md-7 col-lg-8">
                                        <h1 className="header-light">{eventTitle}</h1>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-5">
                                                <div className="event-context">
                                                    <figure>
                                                        <img
                                                            src={
                                                                location
                                                                    ? LocationPhysicalEventSvg
                                                                    : eventDateTime
                                                                    ? LiveWebinarSvg
                                                                    : OnDemandSvg
                                                            }
                                                            alt="event type information"
                                                            role="presentation"
                                                        />
                                                    </figure>
                                                    <span className="h4 header-bold">
                                                        {location ? location : eventType}
                                                    </span>
                                                </div>
                                                <div className="event-detail">
                                                    <div>
                                                        <figure>
                                                            <img
                                                                src="/assets/images/icons/events/language.svg"
                                                                alt="event language"
                                                                role="presentation"
                                                            />
                                                        </figure>
                                                        <span>{eventLanguage}</span>
                                                    </div>
                                                    {subtitlesAvailable && (
                                                        <div>
                                                            <figure>
                                                                <img
                                                                    src="/assets/images/icons/events/closed-caption.svg"
                                                                    alt="event has closed captions"
                                                                    role="presentation"
                                                                />
                                                            </figure>
                                                            <span>Subtitles Available</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-4">
                                                {eventDateTime && (
                                                    <div className="h4 header-bold event-date">
                                                        {outputDate(eventDateTime, 'day')}{' '}
                                                        {outputDate(eventDateTime, 'month')}
                                                    </div>
                                                )}
                                                {deadlineDateTime && (
                                                    <div className="event-deadline">
                                                        <span>Deadline:</span> {outputDate(deadlineDateTime, 'day')}{' '}
                                                        {outputDate(deadlineDateTime, 'month')}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-xs-12 col-sm-3">
                                                {eventDateTime && (
                                                    <div className="h4 header-bold event-date">
                                                        {outputDate(eventDateTime, 'time')}
                                                    </div>
                                                )}
                                                {deadlineDateTime && (
                                                    <div className="event-deadline">
                                                        {outputDate(deadlineDateTime, 'time')}
                                                    </div>
                                                )}
                                                {duration && (
                                                    <div className="event-duration">
                                                        <span>Duration:</span> {duration}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            )}

                            <div className="row">
                                <aside className="col-xs-12 col-sm-3 col-md-3 col-lg-2">
                                    {!mobileWidth && address && renderAddressDetails(address)}
                                    {!mobileWidth &&
                                        Object.values(eventAccessibility[0]).indexOf(true) >= 0 &&
                                        renderAccessibility({
                                            wheelchairAccess,
                                            visualImpairment,
                                            blind,
                                            hardOfHearing,
                                            assistiveListening,
                                            audioDescription,
                                        })}
                                </aside>

                                <div id="main-content" className="col-xs-12 col-sm-9 col-md-7 col-lg-8">
                                    <section
                                        dangerouslySetInnerHTML={{
                                            __html: eventDescription,
                                        }}
                                    ></section>
                                    {speakers.length > 0 && (
                                        <section id="speaker-grid">
                                            <div className="h4 header-bold">Speakers</div>
                                            <Equalizer className="row" byRow={false}>
                                                {speakers.map((speaker, i) => {
                                                    return (
                                                        <div key={i} className="col-xs-12 col-sm-4 speaker-block">
                                                            <figure>
                                                                <img
                                                                    src={speaker.image}
                                                                    alt="speaker image"
                                                                    role="presentation"
                                                                />
                                                                <figcaption>
                                                                    <div className="header-bold">{speaker.name}</div>
                                                                    <p>{speaker.bio}</p>
                                                                </figcaption>
                                                            </figure>
                                                        </div>
                                                    );
                                                })}
                                            </Equalizer>
                                        </section>
                                    )}
                                    {mobileWidth && address && renderAddressDetails(address)}
                                    {mobileWidth &&
                                        Object.values(eventAccessibility[0]).indexOf(true) >= 0 &&
                                        renderAccessibility({
                                            wheelchairAccess,
                                            visualImpairment,
                                            blind,
                                            hardOfHearing,
                                            assistiveListening,
                                            audioDescription,
                                        })}
                                    {// if 'Live Webinar', 'Physical Event' or 'Private Event'
                                    (eventType !== 'On-Demand'
                                        ? // return true if availability bigger than zero
                                          availability > 0
                                        : // if 'On-Demand' return true
                                          true) &&
                                        // if 'Live Webinar', 'Physical Event' or 'Private Event'
                                        (eventType !== 'On-Demand'
                                            ? // return true if now less than deadlineDateTime
                                              outputNow() < deadlineDateTime
                                            : // if 'On-Demand' return true
                                              true) && (
                                            <section id="marketo-form">
                                                <div className="h4 header-bold">Register Now</div>
                                                <form id="register-now">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="field-firstName">
                                                                    <span>
                                                                        {/* {miniGazelle.label(
                                        'rfiSubmissionField_firstName',
                                        true
                                      )} */}
                                                                        First Name
                                                                    </span>
                                                                    *
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="field-firstName"
                                                                    className="form-control"
                                                                    name="firstName"
                                                                    onInput={e =>
                                                                        handleFieldInteraction(
                                                                            e,
                                                                            'fieldInteraction',
                                                                            'error1'
                                                                        )
                                                                    }
                                                                    onBlur={e =>
                                                                        handleFieldInteraction(
                                                                            e,
                                                                            'fieldInteraction',
                                                                            'error1'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="field-lastName">
                                                                    <span>
                                                                        {/* {miniGazelle.label(
                                        'lastNameFormField',
                                        true
                                      )} */}
                                                                        Last Name
                                                                    </span>
                                                                    *
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="field-lastName"
                                                                    className="form-control"
                                                                    name="lastName"
                                                                    onInput={e =>
                                                                        handleFieldInteraction(
                                                                            e,
                                                                            'fieldInteraction',
                                                                            'error2'
                                                                        )
                                                                    }
                                                                    onBlur={e =>
                                                                        handleFieldInteraction(
                                                                            e,
                                                                            'fieldInteraction',
                                                                            'error2'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="field-phoneNumber">
                                                                    <span>
                                                                        {/* {miniGazelle.label(
                                        'rfiSubmissionField_phoneNbr',
                                        true
                                      )} */}
                                                                        Phone Number
                                                                    </span>
                                                                    *
                                                                </label>
                                                                <input
                                                                    type="tel"
                                                                    id="field-phoneNumber"
                                                                    className="form-control"
                                                                    maxLength="20"
                                                                    name="phoneNumber"
                                                                    onInput={e =>
                                                                        handleFieldInteraction(
                                                                            e,
                                                                            'fieldInteraction',
                                                                            'error3'
                                                                        )
                                                                    }
                                                                    onBlur={e =>
                                                                        handleFieldInteraction(
                                                                            e,
                                                                            'fieldInteraction',
                                                                            'error3'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="field-emailAddress">
                                                                    <span>
                                                                        {/* {miniGazelle.label(
                                        'rfiSubmissionField_emailAddress',
                                        true
                                      )} */}
                                                                        Email Address
                                                                    </span>
                                                                    *
                                                                </label>
                                                                <input
                                                                    type="email"
                                                                    id="field-emailAddress"
                                                                    className="form-control"
                                                                    name="emailAddress"
                                                                    onInput={e =>
                                                                        handleFieldInteraction(
                                                                            e,
                                                                            'fieldInteraction',
                                                                            'error4'
                                                                        )
                                                                    }
                                                                    onBlur={e =>
                                                                        handleFieldInteraction(
                                                                            e,
                                                                            'fieldInteraction',
                                                                            'error4'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="field-city">
                                                                    <span>
                                                                        {/* {miniGazelle.label(
                                        'townCityFormField',
                                        true
                                      )} */}
                                                                        Town/City
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="field-city"
                                                                    className="form-control"
                                                                    name="city"
                                                                    // no error required, so no handleFieldInteraction
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="field-country">
                                                                    <span>
                                                                        {/* {miniGazelle.label(
                                        'enhancedRfi_ContactsTableCountry',
                                        true
                                      )} */}
                                                                        Country
                                                                    </span>
                                                                    *
                                                                </label>
                                                                <div className="select-wrapper">
                                                                    <select
                                                                        id="field-country"
                                                                        className="form-control"
                                                                        name="country"
                                                                        defaultValue="none"
                                                                        onInput={e =>
                                                                            handleFieldInteraction(
                                                                                e,
                                                                                'fieldInteraction',
                                                                                'error5'
                                                                            )
                                                                        }
                                                                        onBlur={e =>
                                                                            handleFieldInteraction(
                                                                                e,
                                                                                'fieldInteraction',
                                                                                'error5'
                                                                            )
                                                                        }
                                                                    >
                                                                        <option value="none" disabled>
                                                                            {/* {miniGazelle.label(
                                          'enhancedRfi_SelectOption',
                                          true
                                        )} */}
                                                                            Select
                                                                        </option>
                                                                        {this.state.countriesList.map((option, i) => {
                                                                            return (
                                                                                <option key={i} value={option.value}>
                                                                                    {/* {miniGazelle.label(option.label, true)} */}
                                                                                    {option.text}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                    <div className="select-arrow icon icon-3x icon-down-arrows"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {(eventType === 'Live Webinar' || eventType === 'On-Demand') && (
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="field-companyType">
                                                                        <span>
                                                                            {/* {miniGazelle.label(
                                          'companyTypeFormField',
                                          true
                                        )} */}
                                                                            Company Type
                                                                        </span>
                                                                        *
                                                                    </label>
                                                                    {/* <input
                                    type="text"
                                    id="field-company"
                                    className="form-control"
                                    name="company"
                                    onInput={e =>
                                      handleFieldInteraction(
                                        e,
                                        'fieldInteraction',
                                        'error6'
                                      )
                                    }
                                    onBlur={e =>
                                      handleFieldInteraction(
                                        e,
                                        'fieldInteraction',
                                        'error6'
                                      )
                                    }
                                  /> */}
                                                                    <div className="select-wrapper">
                                                                        <select
                                                                            id="field-companyType"
                                                                            className="form-control"
                                                                            name="companyType"
                                                                            defaultValue="none"
                                                                            onInput={e =>
                                                                                handleFieldInteraction(
                                                                                    e,
                                                                                    'fieldInteraction',
                                                                                    'error8'
                                                                                )
                                                                            }
                                                                            onBlur={e =>
                                                                                handleFieldInteraction(
                                                                                    e,
                                                                                    'fieldInteraction',
                                                                                    'error8'
                                                                                )
                                                                            }
                                                                        >
                                                                            <option value="none" disabled>
                                                                                {/* {miniGazelle.label(
                                            'enhancedRfi_SelectOption',
                                            true
                                          )} */}
                                                                                Select
                                                                            </option>
                                                                            {this.state.companyTypesList.map(
                                                                                (option, i) => {
                                                                                    return (
                                                                                        <option
                                                                                            key={i}
                                                                                            value={option.value}
                                                                                        >
                                                                                            {/* {miniGazelle.label(option.label, true)} */}
                                                                                            {option.text}
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </select>
                                                                        <div className="select-arrow icon icon-3x icon-down-arrows"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="field-jobRole">
                                                                        <span>
                                                                            {/* {miniGazelle.label(
                                          'jobRoleFormField',
                                          true
                                        )} */}
                                                                            Job Role
                                                                        </span>
                                                                        *
                                                                    </label>
                                                                    {/* <input
                                    type="text"
                                    id="field-jobRole"
                                    className="form-control"
                                    name="jobRole"
                                    onInput={e =>
                                      handleFieldInteraction(
                                        e,
                                        'fieldInteraction',
                                        'error7'
                                      )
                                    }
                                    onBlur={e =>
                                      handleFieldInteraction(
                                        e,
                                        'fieldInteraction',
                                        'error7'
                                      )
                                    }
                                  /> */}
                                                                    <div className="select-wrapper">
                                                                        <select
                                                                            id="field-jobRole"
                                                                            className="form-control"
                                                                            name="title"
                                                                            defaultValue="none"
                                                                            onInput={e =>
                                                                                handleFieldInteraction(
                                                                                    e,
                                                                                    'fieldInteraction',
                                                                                    'error9'
                                                                                )
                                                                            }
                                                                            onBlur={e =>
                                                                                handleFieldInteraction(
                                                                                    e,
                                                                                    'fieldInteraction',
                                                                                    'error9'
                                                                                )
                                                                            }
                                                                        >
                                                                            <option value="none" disabled>
                                                                                {/* {miniGazelle.label(
                                            'enhancedRfi_SelectOption',
                                            true
                                          )} */}
                                                                                Select
                                                                            </option>
                                                                            {this.state.titlesList.map((option, i) => {
                                                                                return (
                                                                                    <option
                                                                                        key={i}
                                                                                        value={option.value}
                                                                                    >
                                                                                        {/* {miniGazelle.label(option.label, true)} */}
                                                                                        {option.text}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                        <div className="select-arrow icon icon-3x icon-down-arrows"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {(eventType === 'Physical Event' ||
                                                        eventType === 'Private Event') && (
                                                        <div>
                                                            <p id="disclaimer-text">
                                                                Sony Europe B.V. would like to collect information about
                                                                your accessibility requirements and allergy information
                                                                in order for us to accommodate your personal needs.
                                                                Further details, including how we use your data and our
                                                                legal grounds for doing so, how we share your
                                                                information, and your rights, can be found in our{' '}
                                                                <a href="privacy-policy" target="_blank">
                                                                    Privacy Policy
                                                                </a>
                                                                .
                                                            </p>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="field-accessibilityNeeds">
                                                                            <span>Accessibility needs</span>
                                                                        </label>
                                                                        <div className="select-wrapper">
                                                                            <Dropdown
                                                                                id="field-accessibilityNeeds"
                                                                                className="form-control"
                                                                                name="accessibilityNeeds"
                                                                                // no error required, so no handleFieldInteraction
                                                                                classNamePrefix="react-select"
                                                                                isMulti={true}
                                                                                isSearchable={false}
                                                                                isClearable={false}
                                                                                value={this.state.selectedNeeds}
                                                                                onChange={e =>
                                                                                    this.handleMultiSelect(
                                                                                        e,
                                                                                        'accessibilityNeeds'
                                                                                    )
                                                                                }
                                                                                options={this.state.needsList}
                                                                                placeholder={miniGazelle.label(
                                                                                    'enhancedRfi_SelectOption'
                                                                                )}
                                                                            />
                                                                            <div className="select-arrow icon icon-3x icon-down-arrows"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="field-allergies">
                                                                            <span>Allergies</span>
                                                                        </label>
                                                                        <div className="select-wrapper">
                                                                            <Dropdown
                                                                                id="field-allergies"
                                                                                className="form-control"
                                                                                name="allergies"
                                                                                // no error required, so no handleFieldInteraction
                                                                                classNamePrefix="react-select"
                                                                                isMulti={true}
                                                                                isSearchable={false}
                                                                                isClearable={false}
                                                                                value={this.state.selectedAllergies}
                                                                                onChange={e =>
                                                                                    this.handleMultiSelect(
                                                                                        e,
                                                                                        'allergies'
                                                                                    )
                                                                                }
                                                                                options={this.state.allergiesList}
                                                                                placeholder={miniGazelle.label(
                                                                                    'enhancedRfi_SelectOption'
                                                                                )}
                                                                            />
                                                                            <div className="select-arrow icon icon-3x icon-down-arrows"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="field-company">
                                                                    <span>
                                                                        {/* {miniGazelle.label(
                                        'rfiSubmissionField_companyName',
                                        true
                                      )} */}
                                                                        Company Name
                                                                    </span>
                                                                    *
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="field-company"
                                                                    className="form-control"
                                                                    name="company"
                                                                    onInput={e =>
                                                                        handleFieldInteraction(
                                                                            e,
                                                                            'fieldInteraction',
                                                                            'error10'
                                                                        )
                                                                    }
                                                                    onBlur={e =>
                                                                        handleFieldInteraction(
                                                                            e,
                                                                            'fieldInteraction',
                                                                            'error10'
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="checkbox">
                                                            <label htmlFor="field-promotions">
                                                                <input
                                                                    type="checkbox"
                                                                    id="field-promotions"
                                                                    className="form-control"
                                                                    name="promotions"
                                                                    // no error required, so no handleFieldInteraction
                                                                    aria-label={miniGazelle.label(
                                                                        'marketo_label-WebPromoOptIn__c',
                                                                        true
                                                                    )}
                                                                />
                                                                <span>
                                                                    {/* {miniGazelle.label(
                                      'marketo_label-WebPromoOptIn__c',
                                      true
                                    )} */}
                                                                    Subscribe to receive the latest news, event
                                                                    invitations and exclusive promotions from the
                                                                    industry
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="text-right">
                                                            <input
                                                                id="button-submit"
                                                                type="submit"
                                                                className="btn btn-lg btn-yellow"
                                                                value={miniGazelle.label('rfiFormFieldSubmit', true)}
                                                                onClick={e =>
                                                                    this.handleSubmit(e, eventType, videoLink, capacity)
                                                                }
                                                                disabled={this.state.submitSendingForm}
                                                                aria-label={miniGazelle.label(
                                                                    'rfiFormFieldSubmit',
                                                                    true
                                                                )}
                                                            />
                                                        </div>
                                                        {this.state.submitSendingForm && (
                                                            <div className="text-right">
                                                                <i className="fa fa-refresh fa-spin fa-3x" />
                                                                <span className="sr-only">
                                                                    {miniGazelle.label('loading', true)}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </form>
                                            </section>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SweetAlert
                    type="success"
                    show={this.state.submitSuccess}
                    title=""
                    html={true}
                    text={ReactDOMServer.renderToStaticMarkup(
                        renderSubmit({ mobileBannerImage, status: 'success', eventDetails: eventDetails[0] })
                    )}
                    onConfirm={() => {
                        window.location.href = '/';
                    }}
                />

                <SweetAlert
                    type="error"
                    show={this.state.submitError}
                    title=""
                    html={true}
                    text={ReactDOMServer.renderToStaticMarkup(
                        renderSubmit({ mobileBannerImage, status: 'error', eventDetails: eventDetails[0] })
                    )}
                    onConfirm={() => {
                        // const params = new URL(document.location).searchParams;
                        // params.has('error')
                        //   ? // deal with URL redirect BUG - remove '?error' from URL
                        //     (window.location.href = window.location.href.split('?')[0])
                        // ...redirect above does not work - always parses aliId from Marketo
                        //   : // usual error-handling
                        this.setState({
                            submitError: false,
                        });
                    }}
                />

                <SweetAlert
                    type="error"
                    show={this.state.submitNoAvailability}
                    title=""
                    html={true}
                    text={ReactDOMServer.renderToStaticMarkup(
                        renderSubmit({ mobileBannerImage, status: 'no-availability', eventDetails: eventDetails[0] })
                    )}
                    onConfirm={() => {
                        window.location.href = '/';
                    }}
                />
            </div>
        );
    }
}

export default Event;
