import React from 'react'

const renderAccessibility = ({
    wheelchairAccess,
    visualImpairment,
    blind,
    hardOfHearing,
    assistiveListening,
    audioDescription,
}) => {
    return (
        <section id="accessibility-list">
            <div className="h4 header-bold">Accessibility</div>
            <ul className="no-disc">
                {wheelchairAccess && (
                    <li>
                        <figure>
                            <img src="/assets/images/icons/events/wheelchair-access.svg" alt="" role="presentation" />
                        </figure>
                        <span>Wheelchair access</span>
                    </li>
                )}
                {visualImpairment && (
                    <li>
                        <figure>
                            <img src="/assets/images/icons/events/visual-impairment.svg" alt="" role="presentation" />
                        </figure>
                        <span>Visual impairment</span>
                    </li>
                )}
                {blind && (
                    <li>
                        <figure>
                            <img src="/assets/images/icons/events/blind.svg" alt="" role="presentation" />
                        </figure>
                        <span>Blind</span>
                    </li>
                )}
                {hardOfHearing && (
                    <li>
                        <figure>
                            <img src="/assets/images/icons/events/hard-of-hearing.svg" alt="" role="presentation" />
                        </figure>
                        <span>Hard of hearing</span>
                    </li>
                )}
                {assistiveListening && (
                    <li>
                        <figure>
                            <img src="/assets/images/icons/events/assistive-listening.svg" alt="" role="presentation" />
                        </figure>
                        <span>Assistive listening</span>
                    </li>
                )}
                {audioDescription && (
                    <li>
                        <figure>
                            <img src="/assets/images/icons/events/audio-description.svg" alt="" role="presentation" />
                        </figure>
                        <span>Audio description</span>
                    </li>
                )}
            </ul>
        </section>
    );
};

export default renderAccessibility;
