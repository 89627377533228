import React from 'react';
import MediaQuery from 'react-responsive';
import { isClient } from '../../../../../helpers';
import filterTime from './helpers/filterTime';

export default function Article(props) {
    const { data = {} } = props;
    const { logicalName, title, readTime, bannerImage, mobileBannerImage, showPublishDate } = data;

    return (
        <div className="container-fluid post-article" id={logicalName}>
            <div className="container">
                <div className="row">
                    <MediaQuery query="(min-width: 991px)">
                        {matches => {
                            return !matches && isClient() ? (
                                <div className="col-sm-12 ">
                                    <img
                                        className="mobile-featured"
                                        src={mobileBannerImage}
                                        alt=""
                                        role="presentation"
                                    />
                                </div>
                            ) : (
                                <div className="col-sm-12">
                                    <img className="image-padding" src={bannerImage} alt="" role="presentation" />
                                </div>
                            );
                        }}
                    </MediaQuery>
                </div>
                <div className="row">
                    <div className="col-sm-12 post-article">
                        <h1>{title}</h1>
                        <div className="h4">
                            {showPublishDate && filterTime(props)}
                            {readTime && `– ${readTime}`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
