const outputDate = (timestamp, query) => {
    const date = new Date(timestamp);
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    // const timezoneString = date.toString().split('(').pop().slice(0, -1);
    // const timezoneArray = timezoneString.split(' ');
    // const timezoneAcronym = timezoneArray.map(string => string[0]).join('');
    let output;
    switch (query) {
        case 'month':
            output = months[date.getMonth()];
            break;
        case 'day':
            output = date.getDate();
            break;
        case 'time':
            // commented out to remove timezone accronym
            // output = date.getHours() + ':' + minutes + ' ' + timezoneAcronym;
            output = date.getHours() + ':' + minutes;
            break;
        default:
            output = months[date.getMonth()];
    }
    return output;
};

export default outputDate