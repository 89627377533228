import React from 'react';

import classNames from 'classnames';
import outputDate from '../../outputDate/outputDate';

import OnDemandSvg from '../../../../../../assets/images/icons/events/on-demand.svg';
import LiveWebinarSvg from '../../../../../../assets/images/icons/events/live-webinar.svg';
import LocationPhysicalEventSvg from '../../../../../../assets/images/icons/events/location-physical-event.svg';

const renderSubmit = ({ mobileBannerImage, status, eventDetails }) => {
    const { eventTitle, eventType, eventDateTime, duration, contactEmail, location } = eventDetails;
    return (
        <div
            id="submit-dialog"
            className={classNames({
                'error-dialog': status === 'error' || status === 'no-availability',
            })}
        >
            {status === 'success' && (
                <div>
                    <div className="submission-status">
                        <figure>
                            <img src="/assets/images/icons/events/tick-icon.svg" alt="" role="presentation" />
                        </figure>
                        <span className="h4 header-bold">Booking Confirmed</span>
                    </div>
                </div>
            )}
            {(status === 'error' || status === 'no-availability') && (
                <div>
                    <div className="submission-status">
                        <figure>
                            <img src="/assets/images/icons/events/cross-icon.svg" alt="" role="presentation" />
                        </figure>
                        {status === 'error' && <span className="h4 header-bold">Booking Failed. Please Try Again</span>}
                        {status === 'no-availability' && (
                            <span className="h4 header-bold">Sorry, booking is closed for this event</span>
                        )}
                    </div>
                </div>
            )}
            <div className="clearfix row">
                <div className="col-xs-12 col-sm-6">
                    <figure className="event-image">
                        <img src={mobileBannerImage} alt="" role="presentation" />
                    </figure>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <h2 className="header-light">{eventTitle}</h2>
                    <div className="event-context">
                        <figure>
                            <img
                                src={
                                    location
                                        ? LocationPhysicalEventSvg
                                        : eventDateTime
                                        ? LiveWebinarSvg
                                        : OnDemandSvg
                                }
                                alt=""
                                role="presentation"
                            />
                        </figure>
                        <span className="h4 header-bold">{location ? location : eventType}</span>
                    </div>
                    {eventDateTime && (
                        <div className="h4 header-bold event-date">
                            {outputDate(eventDateTime, 'day')} {outputDate(eventDateTime, 'month')}
                        </div>
                    )}
                    {eventDateTime && (
                        <div className="h4 header-bold event-date">{outputDate(eventDateTime, 'time')}</div>
                    )}
                    {duration && (
                        <div className="event-duration">
                            <span>Duration:</span> {duration}
                        </div>
                    )}
                    <p className="contact-us">
                        Contact us: <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                    </p>
                    {status === 'success' && <p>You will receive an email confirmation shortly.</p>}
                </div>
            </div>
        </div>
    );
};

export default renderSubmit;
