import sokLocales from '../../../../../../assets/locales/sokLocales';
import { currentLocale, miniGazelle } from '../../../../../../helpers';

function filterTime(props) {
    const { data = {} } = props || {};

    const { publishDateAndTime } = data || {};

    const date = new Date(publishDateAndTime);

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    if (sokLocales.includes(currentLocale.get())) {
        return `${year}년 ${miniGazelle.label(`month_${month.toString()}`, true)} ${day}일`;
    } else {
        return `${miniGazelle.label(`month_${month.toString()}`, true)} ${day}, ${year}`;
    }
}

export default filterTime;